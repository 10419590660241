import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({  
  breeze: {
    backgroundColor: theme.palette.breeze
  },
  darkOlive: {
    backgroundColor: theme.palette.darkOlive
  },
  englishViolet: {
    backgroundColor: theme.palette.englishViolet
  },
  indigo:  {
    backgroundColor: theme.palette.indigo
  },
  umber:  {
    backgroundColor: theme.palette.umber
  }
}));

function HeadingActionCard(props) {
  const { title, paletteColor, actionText, actionUrl, subHeading, bodyContent } = props;
  const classes = useStyles();

  return (    
    <Card>
      <CardActionArea href={actionUrl} target="_blank" rel="noreferrer">
        <CardHeader
          title={title}
          className={classes[paletteColor]}
        />
        <CardContent>          
          <Typography variant="h5" component="p">
            {subHeading}
          </Typography> 
          <Typography variant="body1" component="p">
            {bodyContent}
          </Typography>          
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button color="primary" href={actionUrl} target="_blank" rel="noreferrer">
          {actionText}
        </Button>
      </CardActions>
    </Card>
  );
}
export default HeadingActionCard;