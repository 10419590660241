import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#005cb9",
      light: "#5CADFF",
      dark: "#001429",
    },
    secondary: {
      main: "#58585a",
      light: "#EEEEEE",
      dark: "#282829",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#596173",
      dark: "#1A1D23",
    },
    indigo: "#2A4158",
    englishViolet: "#4C394F",
    umber: "#574638",
    darkOlive: "#476622",
    breeze: "#007bff",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundImage:
            "url(https://centerpointesc.com/images/grass-background3.jpg)",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        },
      },
    },
    MuiButton: {
      root: {
        margin: "5px",
      },
    },
    MuiCard: {
      root: {
        padding: 0,
      },
    },
    MuiCardContent: {
      root: {
        backgroundColor: "#EEEEEE",
        textAlign: "left",
      },
    },
    MuiCardHeader: {
      root: {
        color: "white",
        backgroundColor: "#005cb9",
      },
    },
    MuiPaper: {
      root: {
        padding: "20px 10px",
        margin: "20px 10px",
        backgroundColor: "#fff", // 5d737e
      },
    },
  },
});
export default theme;
