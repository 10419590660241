import React, { useState } from "react";
import Parser from 'rss-parser';
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Typography,
  Paper
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Sermon from './Sermon';
import years from './../years';

const parser = new Parser();
const useStyles = makeStyles((theme) => ({
  searchHeader: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  searchButton: {
    color: "white"
  },
  searchIcon: {
    height: "32px"
  },
  currentYear: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  searchField: {
    margin: "0 20px",
    width: "calc(100% - 120px)"
  }
}));

function SermonSearch(props) {
  const { formatDate, selectAudio } = props;
  const classes = useStyles();
  const [ sermonsLoaded, setSermonsLoaded ] = useState(false);
  const [sermonFeed, setSermonFeed] = useState({});
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchExecuted, setSearchExecuted] = useState(false);

  if (!sermonsLoaded) {
    let sermons;

    years.forEach((year, i) => {
      let sermonFeedUrl = "/sermons/sermons.xml"
      if (i !== 0)
        sermonFeedUrl = "/sermons/sermons" + year + ".xml";
      
      parser.parseURL(sermonFeedUrl)
        .then(result => {
          if (i === 0)
            sermons = result;
          else
            result.items.forEach(sermon => sermons.items.push(sermon))

          setSermonFeed(sermons);
        });
    });

    setSermonsLoaded(true);
  }

  const handleSearchTextChange = (event) => {    
    setSearchText(event.target.value);
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      search();
    }
  }

  const search = () => {    
    let results = [];

    if (!searchText) {
      setSearchExecuted(false);
    } else {
      sermonFeed.items.forEach((sermon) => {
        if (sermon.title.toLowerCase().includes(searchText.toLowerCase()) || (sermon.subtitle && sermon.subtitle.toLowerCase().includes(searchText.toLowerCase()))) {
          results.push(sermon);
        }
      });

      setSearchExecuted(true);
    }

    setSearchResults(results);
  }

  const renderSearchResults = () => {
    if (searchResults) {
      return searchResults.map((sermon, i) => (
        <Sermon sermon={sermon} formatDate={formatDate} selectAudio={selectAudio} key={i} />
      ));
    }
  }

  const renderEmptyInputCard = () => {
    if (!searchExecuted)
      return (
        <Paper className={classes.header}>
          <Typography>
            Enter words or phrases to search for sermons by title or subtitle.
          </Typography>
        </Paper>
      );
  }
  
  const renderEmptyResultsCard = () => {
    if (searchExecuted && searchResults && searchResults.length === 0)
      return (
        <Paper className={classes.header}>
          <Typography>
            No sermons found that match your search criteria.
          </Typography>
        </Paper>
      );
  };

  return (
    <>
      <Paper className={classes.searchHeader}>
        <TextField className={classes.searchField} label="Search Sermons" value={searchText} onChange={handleSearchTextChange} onKeyPress={handleKeyPress}></TextField>
        <Button variant="contained" color="primary" className={classes.searchButton} onClick={search}>
          <SearchIcon className={classes.searchIcon} />
        </Button>
      </Paper>
      {renderSearchResults()}
      {renderEmptyInputCard()}
      {renderEmptyResultsCard()}
    </>
  );
}
export default SermonSearch;
