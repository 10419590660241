import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  breezeHeader: {
    backgroundColor: theme.palette.breeze
  },
  breezeContent: {
    backgroundColor: "white",
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "horizontal"
  },
  breezeTextSubCard: {    
    backgroundColor: "white",
    padding: "10px 0 0 0",
    maxWidth: "320px",
    textAlign: "left"
  },
  breezeLogoSubCard: {
    padding: 30,
    backgroundColor: theme.palette.breeze,
    maxWidth: "180px"
  }
}));

function BreezeLogin() {
  const classes = useStyles(); 

  return (    
    <Card>
      <CardActionArea href="https://centerpointesc.breezechms.com" target="_blank" rel="noreferrer">
        <CardHeader className={classes.breezeHeader}
          title="BREEZE LOGIN">
        </CardHeader>
        <CardContent className={classes.breezeContent}>
          <Card elevation={0} className={classes.breezeTextSubCard}>
            <CardContent className={classes.breezeContent}>
              <Typography variant="body2">
                If you have a Center Pointe account on the Breeze platform, 
                you can click here to log in.
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.breezeLogoSubCard}>
            <CardMedia
              component="img"
              alt="Breeze"
              image="https://centerpointesc.com/images/breeze-logo-white.png">
            </CardMedia>
          </Card>
        </CardContent>
        <CardContent>
          <Typography variant="body2" component="p">
            Breeze is our church management partner, and logging into your 
            account gives you access to our member directory and your giving
            records. You can also take advantage of online giving with a simple
            interface for spontaneous giving or you can manage your giving on 
            a recurring schedule that is fully configurable.
          </Typography>
        </CardContent>
      </CardActionArea>        
      <CardActions>
        <Button color="primary" href="https://centerpointesc.breezechms.com" target="_blank" rel="noreferrer">
          Log in to Breeze
        </Button>
      </CardActions>
    </Card>
  );
}
export default BreezeLogin;