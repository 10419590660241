import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActionArea, CardActions, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  header: {    
    backgroundColor: "#B9A89B",
    textAlign: "center",
  },
  wordmark: {
    width: "140px"
  },
  logoContent: {
    textAlign: "center",
    padding: "20px"
  },
  icon: {
    width: "20vmin"
  }
}));

function DownloadYouVersion() {
  const classes = useStyles();

  return (    
    <Card>
      <CardContent className={classes.header}>
        <img className={classes.wordmark} src="https://centerpointesc.com/images/YouVersion_wordmark.png" alt="YouVersion" />
      </CardContent>
      <CardActionArea href="https://bible.com/app" target="_blank" rel="noreferrer">
        <CardContent className={classes.textContent}>
          <Typography variant="body2" component="p">
            We recommend the Bible App, also known as YouVersion, for your phone. It's free and provides a growing number of translations and languages, as well as bookmarks, highlighting, reading plans, notes and more. Many of the translations are also available with audio so it can read the Bible to you.
          </Typography>          
        </CardContent>
        <CardContent className={classes.logoContent}>
          <img className={classes.icon} src="https://centerpointesc.com/images/YouVersion_icon.png" alt="Download YouVersion" />
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button color="primary" href="https://bible.com/app" target="_blank" rel="noreferrer">
          Download the Bible App
        </Button>
      </CardActions>
    </Card>
  );
}
export default DownloadYouVersion;