import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Card, CardContent, CardHeader, CardMedia, CardActions } from '@material-ui/core';
import LogoCardSimple from './LogoCardSimple';

const useStyles = makeStyles(theme => ({
  textSubCard: {
    padding: 0,
    maxWidth: "320px"
  },
  breezeLogoSubCard: {
    backgroundColor: theme.palette.breeze,
    padding: 40,
    maxWidth: "280px"
  },
  cardContent: {
    backgroundColor: "white",
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "horizontal"
  },
  captionTextContent: {
    backgroundColor: "white"
  },
  giveNowActions: {
    justifyContent: "center"
  },
  giveButton: {
    marginBottom: "30px",
    height: "50px",
    width: "200px"
  }
}));

function Give() {
  const classes = useStyles();

  return (
    <>
      <LogoCardSimple />
      <Card>        
        <CardHeader
          title="ONLINE GIVING">
        </CardHeader>
        <CardContent className={classes.cardContent}>
          <Card elevation={0} className={classes.textSubCard}>
            <CardContent className={classes.captionTextContent}>
              <Typography>
                Center Pointe Community Church offers the option to give online safely and securely, 
                provided by our church management software partner, Breeze.
              </Typography>
            </CardContent>
          </Card>
          <Card className={classes.breezeLogoSubCard}>
            <CardMedia
              component="img"
              alt="Breeze"
              image="https://centerpointesc.com/images/breeze-logo-white.png">
            </CardMedia>
          </Card>
        </CardContent>
        <CardActions className={classes.giveNowActions}>
          <Button
            variant="contained"
            color="primary"
            className={classes.giveButton}
            href="https://centerpointesc.breezechms.com/give/online"
            target="_blank"
            rel="noreferrer">
            <Typography variant="h6">
              Give Now
            </Typography>
          </Button>
        </CardActions>
        <CardContent>
          <Typography>
            If you have a Center Pointe account on the Breeze platform, you can log in to see your
            giving history and optionally manage your giving on a recurring schedule that is fully configurable.
            Once you are logged in, click on More &gt; Give Now where you can manage Recurring Gifts 
            and Payment Methods.
          </Typography>
          <br></br>
          <Typography>
            If you would like to request a Center Pointe account, you can do so by submitting your
            contact information. If you provide an email address, you will receive a confirmation
            upon submission of the form, as well as an email with instructions on creating a Breeze account.
          </Typography>
        </CardContent>
        <CardActions className={classes.loginActions}>
          <Button
            color="primary"
            href="https://centerpointesc.breezechms.com"
            target="_blank"
            rel="noreferrer">
            Log in to Breeze
          </Button>
          <Button
            color="primary"
            href="https://centerpointesc.breezechms.com/form/UpdateInformation"
            target="_blank"
            rel="noreferrer">
            Update contact info
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
export default Give;