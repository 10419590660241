import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActions, CardMedia, CardContent, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles(theme => ({
  cardActions: {
    padding: 0,
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center'
  },
  listenButton: {
    margin: 0,
    color: "white",
    height: "72px",
    width: "100%"
  },
  listenIcon: {
    height: "20px",
    width: "32px"
  },
  link: {
    textDecoration: "none"
  }
}));

function MarriageMatters(props) {
  const { event, formatDate, selectAudio, showArchiveLink } = props;
  const classes = useStyles();
  
  let archiveLink;
  if (showArchiveLink)
    archiveLink = <CardActions hidden={!showArchiveLink}>
                    <Link to="/marriage-matters" className={classes.link}>
                      <Button color="primary" to="/marriage-matters">
                        Go to Marriage Matters Archive
                      </Button>
                    </Link>
                  </CardActions>;

  return (
    <Card>
      <CardMedia
        component="img"
        alt={event.title}
        image={event.itunes.image}
        title={event.title}
      />
      <CardContent>
        <Typography variant="h6" component="p">
          {event.title}
        </Typography>
        <Typography hidden={!event.itunes.subtitle} variant="body2" component="p">
          {event.itunes.subtitle}
        </Typography>
        <Typography variant="body1" component="p">
          {formatDate(event.pubDate)}
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button className={classes.listenButton} onClick={() => selectAudio(event)}>
          Listen
          <OpenInNewIcon className={classes.listenIcon} />
        </Button>
      </CardActions>
      {archiveLink}
    </Card>
  );
}
export default MarriageMatters;