import React from 'react';
import { HashRouter, Route, Switch, NavLink, Redirect } from 'react-router-dom';
import theme from '../theme';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { AppBar, Container, Toolbar } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import PowerIcon from '@material-ui/icons/Power';
import FavoriteIcon from '@material-ui/icons/Favorite';

import ScrollToTop from './ScrollToTop';
import Home from './Home';
import About from './About';
import Connect from './Connect';
import Give from './Give';
import SermonArchive from './SermonArchive';
import SermonSearch from './SermonSearch';
import MarriageMattersArchive from './MarriageMattersArchive';
import Footer from './Footer';
import AudioPlayer from './AudioPlayer';

const useStyles = makeStyles({
  container: {
    padding: 0,
    textAlign: "center"
  },
  appbar: {
    margin: 0,
    padding: "0 0 1px 0",
    backgroundColor: theme.palette.background.dark
  },
  toolbar: {
    justifyContent: "center",
    padding: "0 10px"
  },
  navlink: {
    padding: "10px",
    maxWidth: 235,
    minWidth: 72,
    width: "100%",
    textAlign: "center",
    color: theme.palette.secondary.light,
    textDecoration: "none"
  },
  activeNavlink: {    
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    borderWidth: "0 0 3px 0"
  }
});

function App() {
  const [ audio, setAudio ] = React.useState();

  const classes = useStyles();

  const formatDate = (pubDate => {
    let date = new Date(pubDate);
    return date.toLocaleDateString();
  });

  const selectAudio = (audio => {
    setAudio(audio);

    setTimeout(() => {
      let audioElement = document.getElementById("audioElement");
      if (audioElement)
        audioElement.play();
    }, 1000);
  });

  let audioPlayer;
  if (audio)
    audioPlayer = <AudioPlayer audio={audio} setAudio={setAudio} />;  

  return (    
    <ThemeProvider theme={theme}>
      <HashRouter basename="/">
      <AppBar position="sticky" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <NavLink to="/home" className={classes.navlink} activeClassName={classes.activeNavlink}>
            <HomeIcon />
            <div>HOME</div>              
          </NavLink>
          <NavLink to="/about" className={classes.navlink} activeClassName={classes.activeNavlink}>
            <InfoIcon />
            <div>ABOUT</div>
          </NavLink>
          <NavLink to="/connect" className={classes.navlink} activeClassName={classes.activeNavlink}>
            <PowerIcon />
            <div>CONNECT</div>
          </NavLink>
          <NavLink to="/give" className={classes.navlink} activeClassName={classes.activeNavlink}>
            <FavoriteIcon />
            <div>GIVE</div>
          </NavLink>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" className={classes.container}>
        <ScrollToTop />
        <Switch>
          <Route path="/home" render={(props) => (
            <Home {...props} formatDate={formatDate} selectAudio={selectAudio} />
          )}/>
          <Route path="/about" component={About} />
          <Route path="/connect" component={Connect} />
          <Route path="/give" component={Give} />
          <Route path="/sermons/:year" render={(props) => (
            <SermonArchive {...props} formatDate={formatDate} selectAudio={selectAudio} />
          )}/>
          <Route path="/sermons" render={(props) => (
            <SermonArchive {...props} formatDate={formatDate} selectAudio={selectAudio} />
          )}/>
          <Route path="/search" render={(props) => (
            <SermonSearch {...props} formatDate={formatDate} selectAudio={selectAudio} />
          )}/>
          <Route path="/marriage-matters" render={(props) => (
            <MarriageMattersArchive {...props} formatDate={formatDate} selectAudio={selectAudio} />
          )}/>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
        </Switch>
        <Footer />        
      </Container>
      {audioPlayer}
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;