import React from "react";
import Parser from 'rss-parser';
import MarriageMatters from './MarriageMatters';

const parser = new Parser();

function LatestMarriageMatters(props) {
  const { formatDate, selectAudio } = props;
  const [initialized, setInitialized] = React.useState(false);
  const [feed, setFeed] = React.useState({
    items: [
      {
        enclosure: {
          url: ""
        },
        itunes: {
          image: ""
        }
      }
    ]
  });

  if (!initialized)  {
    parser.parseURL("/marriage-matters/marriage-matters.xml")
          .then((result) => setFeed(result));
    setInitialized(true);
  }

  return (
    <MarriageMatters event={feed.items[0]} formatDate={formatDate} selectAudio={selectAudio} showArchiveLink />
  );
}
export default LatestMarriageMatters;
