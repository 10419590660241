import React from "react";
import Parser from 'rss-parser';
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Typography,
  Paper,
  Fab,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MarriageMatters from './MarriageMatters';

const parser = new Parser();
const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  fabTop: {
    position: "fixed",
    bottom: "calc(50vh + 16px)",
    right: "16px"
  },
  fabBottom: {
    position: "fixed",
    bottom: "calc(50vh - 72px)",
    right: "16px"
  },
  fabBottomMobile: {
    position: "fixed",
    bottom: "calc(50vh - 56px)",
    right: "16px"
  },
  fabHidden: {
    display: "none"
  }
}));

function MarriageMattersArchive(props) {
  const { formatDate, selectAudio } = props;
  const [initialized, setInitialized] = React.useState(false);
  const [fabTopVisible, setFabTopVisible] = React.useState(false);
  const [fabBottomVisible, setFabBottomVisible] = React.useState(true);
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:599px)');
  const [feed, setFeed] = React.useState({
    items: [
      {
        enclosure: {
          url: ""
        },
        itunes: {
          image: ""
        }
      }
    ]
  });
  
  if (!initialized)  {
    parser.parseURL("/marriage-matters/marriage-matters.xml")
          .then((result) => setFeed(result));
    setInitialized(true);
  }

  const backToTop = () => {
    window.scrollTo(0, 0);
  };

  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  React.useEffect(() => {
    const toggleTopVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setFabTopVisible(true)
      } 
      else {
        setFabTopVisible(false)
      }
    };
  
    const toggleBottomVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled >= (document.body.scrollHeight - window.innerHeight)) {
        setFabBottomVisible(false)
      } 
      else {
        setFabBottomVisible(true)
      }
    };
    
    window.addEventListener('scroll', toggleTopVisible);
    window.addEventListener('scroll', toggleBottomVisible);
    return () => {
      window.removeEventListener('scroll', toggleTopVisible);
      window.removeEventListener('scroll', toggleBottomVisible);
    }
  });

  return (
    <>
      <Paper className={classes.header}>
        <Typography variant="h5" component="div">
          MARRIAGE MATTERS ARCHIVE
        </Typography>
      </Paper>
      {feed.items.map((event, i) => (
        <MarriageMatters event={event} formatDate={formatDate} selectAudio={selectAudio} key={i} />
      ))}
      <Fab
        size={mobile ? "small" : "large"}
        color="primary"
        className={fabTopVisible ? classes.fabTop : classes.fabHidden}
        onClick={backToTop}
      >
        <ArrowUpwardIcon />
      </Fab>
      <Fab
        size={mobile ? "small" : "large"}
        color="primary"
        className={fabBottomVisible ? mobile ? classes.fabBottomMobile : classes.fabBottom : classes.fabHidden}
        onClick={scrollToBottom}
      >
        <ArrowDownwardIcon />
      </Fab>
    </>
  );
}
export default MarriageMattersArchive;
