import React from "react";
import Parser from 'rss-parser';
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Button,
  Container,
  Typography,
  Paper,
  Fab,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import SearchIcon from "@material-ui/icons/Search";
import Sermon from './Sermon';
import years from './../years';

const parser = new Parser();
const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  currentYear: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  yearButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "140px",
    margin: "10px"
  },
  fabTop: {
    position: "fixed",
    bottom: "calc(50vh + 16px)",
    right: "16px"
  },
  fabBottom: {
    position: "fixed",
    bottom: "calc(50vh - 72px)",
    right: "16px"
  },
  fabBottomMobile: {
    position: "fixed",
    bottom: "calc(50vh - 56px)",
    right: "16px"
  },
  fabHidden: {
    display: "none"
  },
  fabSearch: {
    position: "fixed",
    top: "100px",
    right: "16px"
  },
  fabSearchMobile: {
    position: "fixed",
    top: "107px",
    right: "16px"
  }
}));

function SermonArchive(props) {
  const { formatDate, selectAudio } = props;
  const [initializedYear, setInitializedYear] = React.useState(false);
  const { year } = props.match.params;
  const [fabTopVisible, setFabTopVisible] = React.useState(false);
  const [fabBottomVisible, setFabBottomVisible] = React.useState(true);
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:599px)');
  const [sermonFeed, setFeed] = React.useState({    
    title: "",
    year: "",
    items: [
      {
        enclosure: {
          url: ""
        },
        itunes: {
          image: ""
        }
      }
    ]
  });

  let sermonFeedUrl = "/sermons/sermons.xml";
  if (year) sermonFeedUrl = "/sermons/sermons" + year + ".xml";

  if (initializedYear !== year)  {
  parser.parseURL(sermonFeedUrl)
        .then((result) => setFeed(result));
    setInitializedYear(year);
  }
  const backToTop = () => {
    window.scrollTo(0, 0);
  };

  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  React.useEffect(() => {
    const toggleTopVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setFabTopVisible(true)
      } 
      else {
        setFabTopVisible(false)
      }
    };
  
    const toggleBottomVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled >= (document.body.scrollHeight - window.innerHeight)) {
        setFabBottomVisible(false)
      } 
      else {
        setFabBottomVisible(true)
      }
    };
    
    window.addEventListener('scroll', toggleTopVisible);
    window.addEventListener('scroll', toggleBottomVisible);
    return () => {
      window.removeEventListener('scroll', toggleTopVisible);
      window.removeEventListener('scroll', toggleBottomVisible);
    }
  });

  return (
    <>
      <Paper className={classes.header}>
        <Typography variant="h5" component="div">
          SERMON ARCHIVE
        </Typography>
      </Paper>
      <Container maxWidth="lg">
        {years.map((currentYear, i) => (
          <Button style={{ display: year && year < currentYear ? "inline-flex" : "none" }} className={classes.yearButton} variant="contained" color="primary" href={currentYear === years[0] ? "/#/sermons" : "/#/sermons/" + currentYear} key={i}>{currentYear}</Button>
        ))}
      </Container>
      <Paper style={{ display: year ? "block" : "none" }} className={classes.currentYear}>
        <Typography variant="h5" component="div">
          {year} SERMONS
        </Typography>
      </Paper>
      {sermonFeed.items.map((sermon, i) => (
        <Sermon sermon={sermon} formatDate={formatDate} selectAudio={selectAudio} key={i} />
      ))}
      <Container maxWidth="lg">
        {years.map((currentYear, i) => (
          <Button style={{ display: i !== 0 && (!year || year > currentYear) ? "inline-flex" : "none" }} className={classes.yearButton} variant="contained" color="primary" href={"/#/sermons/" + currentYear} key={i}>{currentYear}</Button>
        ))}
      </Container>
      <Fab
        size={mobile ? "small" : "large"}
        color="primary"
        className={fabTopVisible ? classes.fabTop : classes.fabHidden}
        onClick={backToTop}
      >
        <ArrowUpwardIcon />
      </Fab>
      <Fab
        size={mobile ? "small" : "large"}
        color="primary"
        className={fabBottomVisible ? mobile ? classes.fabBottomMobile : classes.fabBottom : classes.fabHidden}
        onClick={scrollToBottom}
      >
        <ArrowDownwardIcon />
      </Fab>
      <Fab
        size={mobile ? "small" : "large"}
        color="primary"
        className={mobile ? classes.fabSearchMobile : classes.fabSearch}
        href="/#/search/"
      >
        <SearchIcon />
      </Fab>
    </>
  );
}
export default SermonArchive;
