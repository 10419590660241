import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardHeader, CardContent, CardActionArea } from '@material-ui/core';
import LogoCardSimple from './LogoCardSimple';

const useStyles = makeStyles(theme => ({
  pastorContent: {
    // backgroundColor: theme.palette.secondary.light,
    backgroundColor: "lightgray",
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "horizontal"
  },
  pastorSubCard: {
    padding: 0,
    minWidth: "320px"
  },
  pastorName: {
    color: "#cccccc",
    backgroundColor: "#2A4158"
  },
  mapHeading: {
    backgroundColor: "lightgray",
    color: theme.palette.primary.main,
  },
  mapFrame: {
    backgroundColor: "#2A4158"
  },
  map: {
    height: "50vmin",
    width: "100%",
    borderWidth: "6px 6px 0 6px",
    borderStyle: "solid",
    borderColor: "#2A4158"
  },
  partnerHeading: {    
    backgroundColor: "#2A4158"
  },
  partnerContent: {
    backgroundColor: "lightgray",
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "horizontal"
  },
  partnerCard: {
    width: "660px"
  }
}));

function About() {
  const classes = useStyles();

  return (
    <>
      <LogoCardSimple />
      <Card>
        <CardHeader className={classes.heading}
          title="WHAT TO EXPECT ON A SUNDAY MORNING">
        </CardHeader>
        <CardContent>
          <Typography variant="body2" component="p">
            Our Sunday morning service is a 90-minute experience designed to help lead people into a growing relationship with Jesus. 
            We always have coffee, juice and snacks on hand in case you didn't get to grab breakfast before you left the house. At 10:30, 
            the band cranks up with about 30 minutes of modern worship. Then kids and youth are invited to take part in age-appropriate 
            classes while the adults stay for teaching that's applicable to every day life.
          </Typography>
          <br></br>
          <Typography variant="body2" component="p">
            The Sunday morning service wraps up at about noon, but folks are encouraged to check out a small group during the week. 
            Life is all about relationships... relationships with God and with other people... and small groups facilitate those relationships.
          </Typography>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className={classes.mapHeading}
          title="WHERE WE MEET"
          subheader="Sundays @ 10:30 a.m.">
        </CardHeader>
        <div className={classes.mapFrame}>
          <iframe
            title="Center Pointe Community Church"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.7503420516127!2d-81.25605098478906!3d33.89608308064868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f89387ba3c156d%3A0x4b77fd1d35e716e4!2s6119%20Platt%20Springs%20Rd%2C%20Lexington%2C%20SC%2029073!5e0!3m2!1sen!2sus!4v1623278934135!5m2!1sen!2sus"
            className={classes.map}
            loading="lazy">
          </iframe>
        </div>
      </Card>
      <Card>
        <CardHeader
          title="OUR PASTORS">
        </CardHeader>
        <CardContent className={classes.pastorContent}>
          <Card className={classes.pastorSubCard}>            
            <CardActionArea href="mailto:david@centerpointesc.com">
              <CardHeader title="David Moore" className={classes.pastorName}></CardHeader>
              <CardContent>
                <Typography>
                  Senior Pastor
                </Typography>
                <Typography>
                  david@centerpointesc.com
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className={classes.pastorSubCard}>
            <CardActionArea href="mailto:greg@centerpointesc.com">
              <CardHeader title="Greg Bolton" className={classes.pastorName}></CardHeader>
              <CardContent>
                <Typography>
                  Associate Pastor of Youth &amp; Families
                </Typography>
                <Typography>
                  greg@centerpointesc.com
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </CardContent>
      </Card>
      <Card>
        <CardHeader
          title="PARTNER MINISTRIES">
        </CardHeader>
        <CardContent>
          <Typography>
            Center Pointe partners with local ministries reaching our community in ways we cannot. When you give to Center Pointe, a portion of your gift goes to support each of these ministries.
          </Typography>
        </CardContent>
        <CardContent className={classes.partnerContent}>
          <Card className={classes.partnerCard}>
            <CardActionArea href="https://www.imb.org" target="_blank">
              <CardHeader title="International Mission Board" className={classes.partnerHeading}></CardHeader>
              <CardContent>
                <Typography variant="h6">
                  https://www.imb.org
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className={classes.partnerCard}>
            <CardActionArea href="https://www.namb.net" target="_blank">
              <CardHeader title="North American Mission Board" className={classes.partnerHeading}></CardHeader>
              <CardContent>
                <Typography variant="h6">
                  https://www.namb.net
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className={classes.partnerCard}>
            <CardActionArea href="https://laviesc.org" target="_blank">
              <CardHeader title="La Vie Pregnancy Care Center" className={classes.partnerHeading}></CardHeader>
              <CardContent>
                <Typography variant="h6">
                  https://laviesc.org
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className={classes.partnerCard}>
            <CardActionArea href="https://www.nkp4kids.org" target="_blank">
              <CardHeader title="Nancy K. Perry Children’s Shelter" className={classes.partnerHeading}></CardHeader>
              <CardContent>
                <Typography variant="h6">
                  https://www.nkp4kids.org
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className={classes.partnerCard}>
            <CardActionArea href="https://www.missionlexingtonsc.org" target="_blank">
              <CardHeader title="Mission Lexington" className={classes.partnerHeading}></CardHeader>
              <CardContent>
                <Typography variant="h6">
                  https://www.missionlexingtonsc.org
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className={classes.partnerCard}>
            <CardActionArea href="https://www.mylba.org" target="_blank">
              <CardHeader title="Lexington Baptist Association" className={classes.partnerHeading}></CardHeader>
              <CardContent>
                <Typography variant="h6">
                  https://www.mylba.org
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className={classes.partnerCard}>
            <CardActionArea href="http://www.lexingtonchristiancounseling.org" target="_blank">
              <CardHeader title="Lexington Christian Counseling" className={classes.partnerHeading}></CardHeader>
              <CardContent>
                <Typography variant="h6">
                  http://www.lexingtonchristiancounseling.org
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className={classes.partnerCard}>
            <CardActionArea href="https://www.facebook.com/groups/106716346072973" target="_blank">
              <CardHeader title="God Speed of South Carolina" className={classes.partnerHeading}></CardHeader>
              <CardContent>
                <Typography variant="h6">
                https://www.facebook.com/groups/106716346072973
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className={classes.partnerCard}>
            <CardActionArea href="https://www.theremnantministrysc.org" target="_blank">
              <CardHeader title="The Remnant Ministry" className={classes.partnerHeading}></CardHeader>
              <CardContent>
                <Typography variant="h6">
                  https://www.theremnantministrysc.org
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </CardContent>
      </Card>
    </>
  );
}
export default About;