import React from 'react';
import LogoCardSimple from './LogoCardSimple';
import HeadingActionCard from './HeadingActionCard';
import BreezeLogin from './BreezeLogin';
import EmailLogin from './EmailLogin';


function Connect() {
  return (
    <>
      <LogoCardSimple />
      <HeadingActionCard
        title="CONTACT US"
        actionText="go to form"
        actionUrl="https://centerpointesc.breezechms.com/form/6c8253"
        bodyContent="If you would like to reach out to us for any reason, you can use this form. We would love to hear from you!"
      />
      <HeadingActionCard
        title="PRAYER REQUESTS"
        paletteColor="indigo"
        actionText="go to form"
        actionUrl="https://centerpointesc.breezechms.com/form/PrayerRequest"
        bodyContent="We count it an honor to pray for you. Please use this form to let us know how we can specifically be praying for you this week."
      />
      <HeadingActionCard
        title="UPDATE CONTACT INFO"
        paletteColor="englishViolet"
        actionText="go to form"
        actionUrl="https://centerpointesc.breezechms.com/form/UpdateInformation"
        bodyContent="If you would like to send us your latest contact information, please fill out this online form. If you provide an email address, you will receive a confirmation upon submission of the form, as well as an email with instructions on creating a Center Pointe account with Breeze if you do not already have one."
      />
      <HeadingActionCard
        title="CPCC CLASS SURVEY"
        paletteColor="umber"
        actionText="go to form"
        actionUrl="https://centerpointesc.breezechms.com/form/85c40f"
        bodyContent="Our 101, 201, 301 and 401 classes represent a journey into a deeper connection with our church, with God and with your purpose in life. You can keep us updated with your progress along this journey by filling out this form."
      />
      <HeadingActionCard
        title="SMALL GROUP SIGNUP"
        paletteColor="darkOlive"
        actionText="go to form"
        actionUrl="https://centerpointesc.breezechms.com/form/SmallGroupSignup"
        bodyContent="Would you like to join a small group? Small groups are the heartbeat of our church. It's where life happens and how we become truly connected at Center Pointe. We would love to help you get plugged in with a great group near you. Just fill out this form and someone will reach out to you soon."
      />
      <BreezeLogin />
      <EmailLogin />
    </>
  );
}
export default Connect;