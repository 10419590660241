import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  audioPlayer: {
    top: "auto",
    bottom: "0",
    margin: 0,
    padding: "20px 10px",
    backgroundColor: theme.palette.background.dark
  },
  audio: {
    width: "100%",
    maxWidth: "593px"
  },
  audioMobile: {
    width: "100%"
  },
  title: {
    minWidth: "292px",
    margin: "0 16px 0 16px",
  },
  titleMobile: {
    minWidth: "292px",
    margin: 0,
  },
  listenButton: {
    color: "white",
    width: "100%"
  },
  toolbar: {
    justifyContent: "center",
    padding: 0
  },
  toolbarMobile: {
    display: "block",
    padding: 0
  },
  spacerMobile: {
    height: "112px"
  },
  closeMobile: {
    position: "absolute",
    bottom: "0",
    right: "4px"
  }
}));

function AudioPlayer(props) {
  const { audio, setAudio } = props;
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:1000px)');

  return (    
    <>
      <AppBar position="fixed" className={classes.audioPlayer}>
        <Toolbar className={mobile ? classes.toolbarMobile : classes.toolbar}>
          <audio id="audioElement" className={mobile ? classes.audioMobile : classes.audio} src={audio.enclosure.url} controls preload="auto">
            <Button className={classes.listenButton} href={audio.enclosure.url} target="_blank" rel="noreferrer">
              Listen in another tab
            </Button>
          </audio>
          <div className={mobile ? classes.titleMobile : classes.title}>
            <Typography variant="h6" component="p">
              {audio.title}
            </Typography>
            <Typography hidden={!audio.itunes.subtitle} variant="body2" component="p">
              {audio.itunes.subtitle}
            </Typography>
          </div>
          <CloseIcon className={mobile ? classes.closeMobile : null} onClick={() => setAudio(undefined)} />
        </Toolbar>
      </AppBar>
      <Toolbar className={mobile ? classes.spacerMobile : null}/>
    </>
  );
}
export default AudioPlayer;