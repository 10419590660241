import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, Typography } from '@material-ui/core';
import logo from './../center-pointe-2020.svg';

const useStyles = makeStyles(theme => ({
  logo: {
    height: "45vmin",
    maxHeight: "500px",
    pointerEvents: "none"
  },
  button: {
    padding: "6px 20px",
    textTransform: "none",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white"
    }
  }
}));

function LogoCardDetailed() {
  const classes = useStyles();  

  return (
    <Paper>
      <img src={logo} className={classes.logo} alt="logo" />
      <Typography variant="subtitle1">
        Join us Sunday mornings at 10:30 a.m.
      </Typography>        
      <Button className={classes.button} href="https://goo.gl/maps/JctJEyyMQAByeJcTA" target="_blank" rel="noreferrer">
        6119 Platt Springs Rd, Lexington, SC
      </Button>
    </Paper>
  );
}
export default LogoCardDetailed;