import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Card, CardContent } from '@material-ui/core';
import whiteLogo from './../center-pointe-2020-white.svg';

const useStyles = makeStyles(theme => ({
  card: {
    padding: "20px 0",
    backgroundColor: "rgba(0, 0, 0, 0)"
  },
  footerContent: {
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0)",
    padding: "0",
    display: "flex",
    flexDirection: "vertical",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  footerContentItem: {
    margin: "0 10px 0 10px"
  },
  whiteLogo: {
    height: "60px"
  },
  infoEmail: {
    color: "white"
  }
}));

function Footer() {
  const classes = useStyles();

  return (
    <Card elevation={0} className={classes.card}>
      <CardContent className={classes.footerContent}>
        <Link to="/home">          
          <img src={whiteLogo} className={classes.whiteLogo} alt="logo" />
        </Link>
      </CardContent>
      <CardContent className={classes.footerContent}>
        <Typography variant="body2" component="p">
          P.O. Box 84458, Lexington, SC 29073
        </Typography>
        <Typography variant="body2" component="p" className={classes.footerContentItem}>
          803 359-6106
        </Typography>
        <a href="mailto:info@centerpointesc.com" className={classes.infoEmail}>
          info@centerpointesc.com
        </a>
      </CardContent>
    </Card>
  );
}
export default Footer;