import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Typography } from '@material-ui/core';
import logo from './../center-pointe-2020.svg';

const useStyles = makeStyles(theme => ({
  cpccEmailContent: {
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "horizontal"
  },
  cpccEmailLogoSubCard: {
    padding: 10,
    backgroundColor: "white",
    maxWidth: "180px"
  },
  logo: {
    height: "85px"
  },
  cpccEmailTextSubCard: {
    padding: "16px 0 0 0",
    backgroundColor: theme.palette.secondary.light,
    maxWidth: "300px",    
    textAlign: "left"
  }
}));

function EmailLogin() {
  const classes = useStyles();
  const actionUrl = "http://mail.centerpointesc.com"

  return (
    <Card>
      <CardActionArea href={actionUrl} target="_blank" rel="noreferrer">
        <CardHeader
          title="CPCC EMAIL LOGIN">
        </CardHeader>
        <CardContent className={classes.cpccEmailContent}>
          <Card className={classes.cpccEmailLogoSubCard}>
            <img src={logo} className={classes.logo} alt="CPCC Email" />
          </Card>
          <Card elevation={0} className={classes.cpccEmailTextSubCard}>
            <CardContent>
              <Typography variant="body2">
                If you have a Center Pointe email address, you can click 
                here to log in to your inbox.
              </Typography>
            </CardContent>
          </Card>
        </CardContent>
      </CardActionArea>        
      <CardActions>
        <Button color="primary" href={actionUrl} target="_blank" rel="noreferrer">
          Log in to CPCC Email
        </Button>
      </CardActions>
    </Card>
  );
}
export default EmailLogin;