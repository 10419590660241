import React from "react";
import Parser from 'rss-parser';
import Sermon from './Sermon';

const parser = new Parser();

function LatestSermon(props) {
  const { formatDate, selectAudio } = props;
  const [initialized, setInitialized] = React.useState(false);
  const [feed, setFeed] = React.useState({
    items: [
      {
        enclosure: {
          url: ""
        },
        itunes: {
          image: ""
        }
      }
    ]
  });

  if (!initialized)  {
  parser.parseURL("/sermons/sermons.xml")
        .then((result) => setFeed(result));
    setInitialized(true);
  }

  return (
    <Sermon sermon={feed.items[0]} formatDate={formatDate} selectAudio={selectAudio} showArchiveLink />
  );
}
export default LatestSermon;
