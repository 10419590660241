import React from 'react';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';

function ImageActionCard(props) {
  const { title, imageUrl, actionText, actionUrl, actions, subHeading, bodyContent } = props;

  return (    
    <Card>
      <CardActionArea href={actionUrl} target="_blank" rel="noreferrer">        
        <CardMedia
          component="img"
          alt={title}
          image={imageUrl}
          title={title}
        />
        <CardContent>          
          <Typography variant="h5" component="p">
            {subHeading}
          </Typography> 
          <Typography variant="body1" component="p">
            {bodyContent}
          </Typography>          
        </CardContent>
      </CardActionArea>
      <CardActions>
        {actionUrl && actionText &&
          <Button color="primary" href={actionUrl} target="_blank" rel="noreferrer">
            {actionText}
          </Button>
        }
        {actions && actions.map((action) => 
          <Button key={action.text} color="primary" href={action.url} target="_blank" rel="noreferrer">
            {action.text}
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
export default ImageActionCard;